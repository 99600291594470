const 	ua 		= window.navigator.userAgent.toLowerCase(),
		appver 		= window.navigator.appVersion.toLowerCase();
let isLegacy 	= appver.indexOf("msie 8.0") > -1 || appver.indexOf("msie 9.0") > -1 || appver.indexOf("msie 10.0") > -1,
	isIE 		= ua.indexOf("msie") !== -1 || navigator.userAgent.match(/Trident/),
	isIE11 		= navigator.userAgent.toLowerCase().indexOf('trident/7') > -1,
	isAndroid 	= navigator.userAgent.match(/Android/i),
	isIPhone 	= navigator.userAgent.match(/iPhone/i),
	isSP 		= navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i),
	isIPad 		= navigator.userAgent.match(/iPad/i),
	isFF 		= ua.indexOf("firefox")!== -1,
	isEdge 		= ua.indexOf("applewebkit") > -1 && ua.indexOf("edge") > -1,
	isMobile	= true,
	isSmallScreen = false,
	isPC = true,
	isWindows = false;
if(isAndroid || isIPhone || isIPad){
	isPC = false;
}else{
	isPC = true;
}
var $html = document.documentElement;
//win or mac
if( navigator.userAgent.indexOf("Win") !== -1 ){
	isWindows = true;
	let ary = ["os_windows"];
	if(isFF){
		ary.push("firefox");
	}else{
		if(isIE){
			if(isIE11){
				ary.push("ie11");
			}
		}else if(isEdge){
			ary.push("edge");
		}else{
		}
	}
	addClassToBody(ary);
}
if(navigator.userAgent.indexOf("Mac") !== -1 ){
	let ary = ["os_mac"];
	if(isFF){
		ary.push("firefox");
	}else{
		if (ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1){
			ary.push("safari");
		}else{
			ary = ["os_mac"];
		}
	}
	addClassToBody(ary);
}
if (navigator.platform == "MacIntel" &&　navigator.userAgent.indexOf("Safari") != -1 &&　navigator.userAgent.indexOf("Chrome") == -1) {
	if (navigator.standalone !== undefined) {
		$html.classList.add("iPad");
		document.querySelector("meta[name='viewport']").setAttribute("content", "viewportContent=width=1300px,initial-scale=.7,user-scalable=no,shrink-to-fit=yes");
	} else {
	// macOS Safari
	}
}
function addClassToBody(ary){
	for(let i = 0; i < ary.length; i++){
		$html.classList.add(ary[i]);
	}
}
var mouse = [];
function object(o) {
	var f = object.f, i, len, n, prop;
	f.prototype = o;
	n = new f;
	for (i=1, len=arguments.length; i<len; ++i){
		for (prop in arguments[i]){
			n[prop] = arguments[i][prop];
		}
	}
	return n;
}
object.f = function(){};

var observer;
var ioArry = [
	{
		"target": ".a",
		"margin": '0px 0px 0px 0px',
		"threshold": [0, 1],
		"change": function(entries){
			//ヘッダーの固定、固定解除-----------------------------------
			let $header = $("header#global_header");
			entries.forEach( function(entry, index){
				let idx = $(".a").index(entry);
				var $block = $(".a").eq(idx);
				observer.unobserve(element);
				if(!$block.hasClass("animate")){
					$block.addClass("animate");
					setTimeout(function(){
						$block.addClass("animated");
					},1000);
				}
			});
		}
	}
]