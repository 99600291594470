var isPage = "";
var mainID = "";
var canResize = false;
var winSize = 0;
var ActionID = { action_id: ""};
var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		//marqueeSlide.init();

		common.checkPage();
		common.start();
		//common.openLoginModal();
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(document).off("scroll touchmove").on("scroll touchmove", function(){
			common.scrl();
		});
		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	start: function(){
		mainID = $("main").attr("id");
		if(isPage === "index"){
			load.topXML();
			common.scrl();
			
		}else{
			common.scrl();
			if(mainID === "thisweek"){
				thisweek.init();
			}else if(mainID === "songlist"){
				songlist.init();
			}else if(mainID === "navigator"){
				navigators.init();
			}else if(mainID === "about"){
				about.init();
			}else if(mainID === "timetable"){
				timetable.init();
			}
		}
		common.nav();
		common.resize();
		common.scrlAnimation();
		setTimeout(function(){
			$("body").removeClass("disable");
		}, 600);
	},
	navforIndex: function(){
		$(".btn_radiko").off().on("click", function(){
			JWAVE.Gigya.NotifyAction({action_id:"action_radiko_button"});
			gtag('event', 'Link', {'event_category': 'radiko','event_label': 'radiko_link'})
			return true;
		});
		$(".ad .slide_bnr li a").off().on("click", function(e){
			//e.preventDefault();
			let idx = $(this).attr("data-id");
			common.clickBnr(idx);
		});
	},
	clickBnr: function(id){
		var _id = Object(ActionID);
		let action = "action_banner_click" + id;
		id = { action_id: action};
		//console.log(id);
		JWAVE.Gigya.NotifyAction(id);
		return true;
	},
	nav: ()=>{
		$("a.btn_login").off().on("click", function(){
			common.openLoginModal();
		});
		$(".footer_content h6 a").off().on("click", function(){
			let idx = $(".footer_content h6 a").index(this);
			let $target = $(".footer_content .col").eq(idx),
				$blk = $target.find(".nav_content");
			if( !$target.hasClass("active") ){
				$target.addClass("active");
				
			}else{
				$target.removeClass("active");
			}
			$blk.animate({
				height: "toggle", opacity: "toggle"
			},200);
		});
		$("a.menu_opener").off("click").on("click", function(){
			if( !$html.classList.contains("menu") ){
				$html.classList.add("menu");
			}else{
				$html.classList.remove("menu");
			}
		});
		$("a.btn_next_onair").off().on("click", function(){
			let $this = $(this);
			$("#block_next_onair").css({
				"top": ( $("a.btn_next_onair").offset().top + $("a.btn_next_onair").outerHeight() + 10 ) + "px"
			});
			if( !$this.hasClass("active") ){
				$this.addClass("active");
				$("#block_next_onair").animate({
					height: "toggle", opacity: "toggle"
				});
			}else{
				$this.removeClass("active");
				$("#block_next_onair").animate({
					height: "toggle", opacity: "toggle"
				});
			}
		});
		$("a.btn_close_modal").off().on("click", function(){
			common.closeModal();
		});
		$("#modal_clip .bg_close").off().on("click", function(){
			common.closeModal();
		});
		$("#modal_login .bg_close").off().on("click", function(){
			common.closeModal();
		});
		/*
		$(document).off("keyup").on("keyup", function(e){
			console.log(e.keyCode);
			if(e.keyCode === 76){
				common.openLoginModal();
			}else if(e.keyCode === 67){
				common.openClipModal();
			}else if(e.keyCode === 81){
				common.closeModal();
			}
		});
		*/
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");

		if (window.matchMedia('all and (min-width : 1100px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}

		if (window.matchMedia('all and (min-width : 1100px)').matches) {
			winSize = 2;
		}else if(window.matchMedia('all and (min-width : 800px)').matches) {
			winSize = 1;
		}else if(window.matchMedia('all and (min-width : 0px)').matches) {
			winSize = 0;
		}
		
		if(isPage === "index"){
			if(canResize){
				topicsSlide.resize();
				spSlideshow.resize();
				top10Slide.resize();
			}
			if( $("a.btn_next_onair").hasClass("active") ){
				$("#block_next_onair").css({
					"top": ( $("a.btn_next_onair").offset().top + $("a.btn_next_onair").outerHeight() + 10 ) + "px"
				});
			}
			
			//-$(".row0, .row1").css({"width": $("body").width() + "px"})
		}
		setTimeout(function(){
			if(isPage === "index"){
				topicsSlide.resize();
			}
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrlAnimation: function(){
		const boxes = document.querySelectorAll(".a");
		//console.log(boxes);
		const options = {
			root: null, // 今回はビューポートをルート要素とする
			rootMargin: "-33% 0px", // ビューポートの中心を判定基準にする
			threshold: [0, 0.5]	//複数指定可
		};
		//
		observer = new IntersectionObserver(doWhenIntersect, options);
		// それぞれのboxを監視する
		$.each(boxes, function(index, box){
			observer.observe(box);
		});
		function doWhenIntersect(entries) {
			// 交差検知をしたもののなかで、isIntersectingがtrueのDOMを色を変える関数に渡す
			for(let i = 0; i < entries.length; i++){
				let e = entries[i];
				if (e.isIntersecting) {
					activateIndex(e.target);
				}
			}
		}
		function activateIndex(element) {
			let idx = $(".a").index(element);
			var $block = $(".a").eq(idx);
			observer.unobserve(element);
			if(!$block.hasClass("animate")){
				$block.addClass("animate");
				if( $block.hasClass("slideshow") ){
					slideshow.init();
				}
				setTimeout(function(){
					$block.addClass("animated");
				},1000);
			}
		}
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	checkPage: function(){
		let arg 		= new Object,
			prop 	= location.pathname,
			arry 	= prop.split("/"),
			len 	= arry.length;
		if(len === 2){
			if(arry[len-1] === "" || arry[len-1] === "index.html" || arry[len-1] === "index_new.html"){
				isPage = "index";
			}else{
				isPage = $("main").attr("id");
			}
		}else{
			isPage = $("main").attr("id");
		}
		let cp = "%c" + isPage;
		//console.log(len, arry);
		//console.log(cp, 'color: pink');
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height();
		if(isPage === "index"){
			if( btmD >= $("#foryou").offset().top ){
				if( !$html.classList.contains("show_header") ){
					$html.classList.add("show_header");
				}
			}else{
				if( $html.classList.contains("show_header") ){
					$html.classList.remove("show_header");
				}
			}
		}else{
			if( !$html.classList.contains("show_header") ){
				$html.classList.add("show_header");
			}
			if(mainID === "thisweek"){
				var stopPos = $("#header_page").offset().top + $("#header_page").outerHeight() - 40 - $("header").outerHeight();
				if(!isSmallScreen){
					var stopPos = $("#header_page").offset().top + $("#header_page").outerHeight() - 30 - $("header").outerHeight();
				}
				//console.log(stopPos, topD)
				if(topD >= stopPos){
					if( !$html.classList.contains("fix_select") ){
						$html.classList.add("fix_select");
					}
				}else{
					if( $html.classList.contains("fix_select") ){
						$html.classList.remove("fix_select");
					}
				}
			}
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	openLoginModal: function(){
		if( !$html.classList.contains("login_modal") ){
			$html.classList.add("login_modal");
			setTimeout(function(){
				$html.classList.add("show_login_modal");
				//CLOSE
				$("a.btn_close_modal").off("click", function(){
					common.closeModal();
				});
			}, 50);
		}
	},
	openClipModal: function(){
		if( !$html.classList.contains("clip_modal") ){
			$html.classList.add("clip_modal");
			setTimeout(function(){
				$html.classList.add("show_clip_modal");
				//CLOSE
				$("a.btn_close_modal").off("click", function(){
					common.closeModal();
				});
			}, 50);
		}
	},
	closeModal: function(){
		if( $html.classList.contains("clip_modal") ){
			$html.classList.remove("show_clip_modal");
			setTimeout(function(){
				$html.classList.remove("clip_modal");
			}, 400);
		}
		if( $html.classList.contains("login_modal") ){
			$html.classList.remove("show_login_modal");
			setTimeout(function(){
				$html.classList.remove("login_modal");
			}, 400);
		}
	},
	getXML: function(url, params){
		var defer 	= $.Deferred();
		var url 	= url;
		$.ajax({
			type: "GET",
			dataType: 'xml',
			url: url,
			data: params,
			success	: defer.resolve,
			error: defer.reject
		});
		return defer.promise();
	},
	activeClip: function(){
		//console.log("active clip button")
		$("a.btn_clip").off("click").on('click',function(e) {
			e.preventDefault();
			var id = $(this).attr("id");
			console.log("CLIP ID:",id);
			$( function(){
				getParam(id);
			});
		});
		function getParam(id){
			add(id);
			common.openClipModal(id);
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});